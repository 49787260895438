/*
 * NB: the JS on this component also does a lot of custom class definitions based on its contents
 */
pre {
    font-size: 16px;
}
@media only screen and (max-device-width: 480px) {
    body {
        -webkit-text-size-adjust: 100%;
    }
}
div.highlight {
    /*margin: -1em 0;*/
}

button.startstop {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 10rem;
    height: 4rem;
    font-size: 2em;
}

div.highlight > pre {
    background-color: #303030;
    padding: 0 2em;
    color: white;
    font-family: monospace;
}

.show-lost {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 0 0 0.5em 0;
    box-sizing: border-box;
    flex-basis: auto;
}

.show-lost a {
    margin-top: 0.3em;
}

/* mobile */
@media only screen and (max-device-width: 850px) {
    .show-lost p {
        text-align: center;
    }
    button.startstop {
        top: unset;
        bottom: 1em;
        right: 1em;
        width: unset;
        height: 1.5em;
        font-size: 40px;
    }
}