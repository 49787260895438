form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 0 0 0.5em 0;
    box-sizing: border-box;
}
textarea {
    margin: 0.4em 0;
    width: 85%;
    background-color: #303030;
    color: #f6f3e8;
    flex-grow: 1;
    border: 0.15rem solid #ccaa8f;
    border-radius: 0.3rem;
    outline: none;
}
textarea::placeholder {
    font-size: 1rem;
}

h1 {
    margin-bottom: 0.1em;
    margin-top: 0.2em;
    padding-inline: 0.2rem;
    text-align: center;
}

h1:after {
    content: "✨"
}

form p {
    margin: 0.3rem auto;
    font-size: 1rem;
}

form #examples {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 1em;
    width: 60%;
    margin-inline: auto;
}

form #examples button {
    flex-grow: 1;
    min-width: 5rem;
    min-height: 1.5rem;
    font-size: 1em;
    height: 2em;
    margin: 0 0.3em;
}

form #examples {
    display: flex;
    margin: 0.3em auto;
}

input[type="submit"] {
    font-size: 1rem;
    min-height: 1.5em;
    min-width: 5rem;
}

a, a:visited {
    color: #f6f3e8;
}

/* mobile */
@media only screen and (max-device-width: 850px) {
    html {
        /*font-size: 25px;*/
    }
    form {
        height: 100dvh;
    }
    form h1 {
        font-size: 1.5rem;
        padding-bottom: 0.4rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    form p {
        font-size: 0.9rem;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    form textarea {
        width: 90%;
        margin: 0 auto;
        flex-grow: 1;
        flex-shrink: 1;
        font-size: 0.6em;
    }
    form textarea::placeholder {
        font-size: 0.8rem;
    }

    form #examples button {
        flex-grow: 1;
        min-width: 5rem;
        min-height: 1.5rem;
        font-size: 1em;
    }
    form input[type='submit'] {
        background-color: #303030;
        color: #f6f3e8;
        font-size: 1em;
        min-height: 1.5rem;
        min-width: 4rem;
        position: absolute;
        bottom: 0.7em;
        right: 0.5em;
    }
    #theme-attribution {
        display: none;
    }
    h1:after {
        content: none;
    }
}